import React from 'react'
import { Bar } from 'react-chartjs-2'
// import styles from './HandRaiseChart.module.css'

const HandRaiseChart = (props) => {
  const data = {
    labels: ["Correct", "Near Correct", "Incorrect", "Not Answered"],
    datasets: [
      {
        label: "Group",
        data: [900, 550, 950, 350],
        borderColor: "",
        backgroundColor: "#7CB5EC",
        pointBackgroundColor: "black",
        pointBorderColor: "red",
      },
      {
        label: "My Class",
        data: [950, 850, 350, 700],
        borderColor: "pink",
        backgroundColor: "#313131",
        pointBackgroundColor: "orange",
        pointBorderColor: "cyan",
        defaultFontSize: 8
      },
    ],
  };
  const options = {
    plugins: {
      labels: false
    },
    title: {
      display: false,
      text: 'Bar chart',
      position: 'top',
      Align: 'end'
    },
    legend: false,
    dataset: {
      barPercentage: 0.6,
      categoryPercentage: 0.7,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
            max: 1250,
            stepSize: 250,
            padding: 20
          },
          gridLines: {
            drawBorder: false
          }
        }
      ],
      xAxes: [{
        gridLines: {
          display: false
        },
        // categoryPercentage: .7,
        // barPercentage:.6,
        ticks: {
          maxTicksLimit: 20,
          padding: 5
        },
      }],
      responsive: true,
    }
  };
  return (
    <div>
      <Bar data={data} options={options} width={300} height={250} />
    </div>
  );
}

export default HandRaiseChart
