import React, { useState, useEffect } from "react";
import { GridContainer, GridItem } from "../../components/grid/index";
import "./index.css";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import search_icon from "../../assets/images/search-icon.png";
import styles from "./Session.module.css";
import { toast } from "react-toastify";
import { getSessionRowsAPI } from "../../api/cube/sessionAPI";
import {
  getModes,
  getOrganiser,
  getStudyCircle,
  getSubjectStudy,
  getAssessmentType,
} from "../../api/masterReadAnalytics/dropdownAPI";
import { sortSession } from "./sortSession";
import filterBuildSession from "./filterBuildSession";
import { whichTimeFrame } from "../../components/whichTImeFrame/whichTimeFrame";
import promiseAllCall from "../../utils/promise";
import AssessmentTableDropdown from "../../components/AssessmentTableDropdown/AssessmentTableDropdown";
import AssessmentTable from "../../components/AssessmentTable/AssessmentTable";

const Session = () => {
  const calendarDate =
    localStorage.getItem("oncalendardate") === null
      ? new Date()
      : new Date(localStorage.getItem("oncalendardate"));
  const [isLoading, setIsLoading] = useState(false);
  const [startdate, setStartDate] = useState(calendarDate);
  const [states, setStates] = useState({
    isMonth: true,
    isDay: false,
    isYear: false,
    t_head: [
      "Date",
      "Group Session #",
      "Title",
      "Type",
      "Organizer",
      "Study Circle",
      // "Attendance",
    ],
    t_body: [],
    modeOptions: [],
    organiserOptions: [],
    typeOptions: [],
    studyOptions: [],
    teacherOptions: [],
    subjectOptions: [],
  });
  const [selectedValues, setselectedValues] = useState({
    modeId: -1,
    organiserId: -1,
    typeId: -1,
    studyCircleId: -1,
    teacherId: -1,
    subjectId: -1,
  });
  const [date, setDate] = useState("");

  const institutionId = window.localStorage.getItem("cmn_school_id");
  const stylesss = {
    monthAct: {
      backgroundColor: "#FD8500",
      color: "#fff",
      fontWeight: 500,
    },
    dayAct: {
      backgroundColor: "#FD8500",
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
      color: "#fff",
      fontWeight: 500,
    },
    yearAct: {
      backgroundColor: "#FD8500",
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
      color: "#fff",
      fontWeight: 500,
    },
  };
  let prevDate;
  let nextDate;
  let filter = [];
  let month = calendarDate.getMonth();
  let today = calendarDate.getDate();
  let year = calendarDate.getFullYear();

  const getDaysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };

  let totaldaysInMonth = getDaysInMonth(month + 1, year);
  let timeFrame = window.localStorage.getItem("timeFrame");
  if (timeFrame) {
    if (timeFrame === "month") {
      prevDate = `${year}-${month + 1}-01`;
      nextDate = year + "-" + (month + 1) + "-" + totaldaysInMonth;
    }
    if (timeFrame === "day") {
      prevDate = year + "-" + (month + 1) + "-" + today;
      nextDate = year + "-" + (month + 1) + "-" + today;
    }
    if (timeFrame === "year") {
      prevDate = year + "-" + 1 + "-" + 1;
      nextDate = year + "-" + 12 + "-" + 31;
    }
  } else {
    prevDate = `${year}-${month + 1}-01`;
    nextDate = year + "-" + (month + 1) + "-" + totaldaysInMonth;
  }

  useEffect(() => {
    const fetchDropdowns = async () => {
      try {
        const callAll = await promiseAllCall([
          getModes(),
          getOrganiser(),
          getAssessmentType(),
          getStudyCircle(institutionId),
        ]);
        setStates((prevState) => {
          return {
            ...prevState,
            modeOptions: callAll[0]?.data?.data,
            organiserOptions: callAll[1]?.data?.data,
            typeOptions: callAll[2]?.data?.data,
            studyOptions: callAll[3]?.data?.data,
          };
        });
      } catch (err) {
        console.log("err inside fetchDropdowns", err);
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        }
      }
    };
    fetchDropdowns();
    dateData();
  }, []);

  useEffect(() => {
    const fetchDropdownDependencies = async () => {
      try {
        const subjects = await getSubjectStudy(
          institutionId,
          selectedValues.studyCircleId,
        );
        setStates((prevState) => {
          return { ...prevState, subjectOptions: subjects?.data?.data };
        });
      } catch (err) {
        console.log("err inside fetchDropdownDependencies", err);
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        }
      }
    };
    fetchDropdownDependencies();
  }, [selectedValues.studyCircleId]);

  useEffect(() => {
    const fetchAssessmentTable = async () => {
      try {
        filter = filterBuildSession(
          institutionId,
          selectedValues.modeId,
          selectedValues.organiserId,
          selectedValues.typeId,
          selectedValues.subjectId,
          prevDate,
          nextDate,
          selectedValues.studyCircleId,
        );
        const tableData = await getSessionRowsAPI(filter);
        console.log("assessmentTableData", tableData?.loadResponses[0]?.data);
        setStates((prevState) => {
          return { ...prevState, t_body: tableData?.loadResponses[0]?.data };
        });
        setIsLoading(false);
      } catch (err) {
        console.log("err inside fetchDropdownDependencies", err);
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        }
        setIsLoading(false);
      }
    };
    setIsLoading(true);
    fetchAssessmentTable();
  }, [
    selectedValues.modeId,
    selectedValues.organiserId,
    selectedValues.studyCircleId,
    selectedValues.subjectId,
    selectedValues.typeId,
    date,
  ]);

  function dateData() {
    if (timeFrame) {
      setStates((prevState) => {
        return {
          ...prevState,
          isMonth: timeFrame === "month" ? true : false,
          isDay: timeFrame === "day" ? true : false,
          isYear: timeFrame === "year" ? true : false,
        };
      });
    }
  }

  const handleClick = async (values) => {
    setIsLoading(true);
    if (values === "d") {
      prevDate = `${year}-${month + 1}-${today}`;
      nextDate = year + "-" + (month + 1) + "-" + today;
    } else if (values === "m") {
      prevDate = `${year}-${month + 1}-01`;
      nextDate = year + "-" + (month + 1) + "-" + totaldaysInMonth;
    } else if (values === "y") {
      prevDate = `${year}-01-01`;
      nextDate = `${year}-12-31`;
    }
    setStates({
      ...states,
      isDay: values === "d" ? true : false,
      isMonth: values === "m" ? true : false,
      isYear: values === "y" ? true : false,
    });
    whichTimeFrame(year, month, today, {
      isDay: values === "d" ? true : false,
      isMonth: values === "m" ? true : false,
      isYear: values === "y" ? true : false,
    });
    console.log(123);
    setDate(Math.random());
    // changeMode("e", "date");
  };

  const handleChange = async (date, arrowdate) => {
    setIsLoading(true);
    if (arrowdate === "prev") {
      let currentdate = new Date(date);
      let previousday = new Date(currentdate.getTime() - 24 * 60 * 60 * 1000);
      let today = states.isDay ? previousday.getDate() : currentdate.getDate();
      let month = states.isMonth
        ? currentdate.getMonth() === 0
          ? 11
          : currentdate.getMonth() - 1
        : previousday.getMonth();
      let year = states.isYear
        ? currentdate.getFullYear() - 1
        : currentdate.getMonth() === 0
          ? currentdate.getFullYear() - 1
          : previousday.getFullYear();
      setStartDate(new Date(year, month, today));
      whichTimeFrame(year, month, today, states);

      prevDate = states.isDay
        ? `${year}-${month + 1}-${today}`
        : states.isMonth
          ? `${year}-${month + 1}-01`
          : states.isYear
            ? `${year}-01-01`
            : null;

      nextDate = states.isDay
        ? `${year}-${month + 1}-${today}`
        : states.isMonth
          ? year + "-" + (month + 1) + "-" + totaldaysInMonth
          : states.isYear
            ? `${year}-12-31`
            : null;
    } else if (arrowdate === "next") {
      let currentdate = new Date(date);
      let previousday = new Date(currentdate.getTime() + 24 * 60 * 60 * 1000);
      let today = states.isDay ? previousday.getDate() : currentdate.getDate();
      let month = states.isMonth
        ? currentdate.getMonth() === 11
          ? 0
          : currentdate.getMonth() + 1
        : previousday.getMonth();
      let year = states.isYear
        ? currentdate.getFullYear() + 1
        : currentdate.getMonth() === 11
          ? currentdate.getFullYear() + 1
          : previousday.getFullYear();
      setStartDate(new Date(year, month, today));
      whichTimeFrame(year, month, today, states);

      prevDate = states.isDay
        ? `${year}-${month + 1}-${today}`
        : states.isMonth
          ? `${year}-${month + 1}-01`
          : states.isYear
            ? `${year}-01-01`
            : null;

      nextDate = states.isDay
        ? `${year}-${month + 1}-${today}`
        : states.isMonth
          ? year + "-" + (month + 1) + "-" + totaldaysInMonth
          : states.isYear
            ? `${year}-12-31`
            : null;
    } else if (arrowdate === "calander") {
      setStartDate(date);
      let date_val = new Date(date);
      localStorage.setItem("oncalendardate", date_val);
      month = date_val.getMonth();
      today = date_val.getDate();
      year = date_val.getFullYear();

      prevDate = states.isDay
        ? `${year}-${month + 1}-${today}`
        : states.isMonth
          ? `${year}-${month + 1}-01`
          : states.isYear
            ? `${year}-01-01`
            : null;

      nextDate = states.isDay
        ? `${year}-${month + 1}-${today}`
        : states.isMonth
          ? year + "-" + (month + 1) + "-" + totaldaysInMonth
          : states.isYear
            ? `${year}-12-31`
            : null;
    }
    setDate(Math.random());
    // changeMode("e", "date");
  };

  const changeMode = async (e, name) => {
    console.log("e, name", e.target.value, name);
    if (name === "mode") {
      setselectedValues((prevState) => {
        return { ...prevState, modeId: e.target.value };
      });
    } else if (name === "organizer") {
      setselectedValues((prevState) => {
        return { ...prevState, organiserId: e.target.value };
      });
    } else if (name === "type") {
      setselectedValues((prevState) => {
        return { ...prevState, typeId: e.target.value };
      });
    } else if (name === "studyCircle") {
      setselectedValues((prevState) => {
        return { ...prevState, studyCircleId: e.target.value };
      });
    } else if (name === "subject") {
      setselectedValues((prevState) => {
        return { ...prevState, subjectId: e.target.value };
      });
    } else return null;
  };

  const tableClick = (selectedrow) => {
    return (window.location.href = "/admin/assessments/" + selectedrow);
  };

  const calandarSection = () => {
    return (
      <div className="calanderContainer">
        <div className="calander">
          <div className="calCont">
            <div className="dateDate">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <img
                  src={require("../../assets/images/prev-arrow.png")}
                  alt="prev"
                  onClick={(e) => handleChange(startdate, "prev")}
                  style={{ marginLeft: "40px" }}
                />
                <Datepicker
                  id={"fromdate"}
                  selected={startdate}
                  onChange={(e) => handleChange(e, "calander")}
                  dateFormat={
                    states.isDay
                      ? "d MMM yyyy"
                      : states.isMonth
                        ? "MMM yyyy"
                        : states.isYear
                          ? "yyyy"
                          : null
                  }
                  className={styles.input}
                  readOnly={false}
                  showMonthYearPicker={states.isMonth ? true : null}
                  showYearDropdown={states.isYear ? true : null}
                  // show={()=>test()}
                />
                <img
                  src={require("../../assets/images/next-arrow.png")}
                  alt="next"
                  onClick={(e) => handleChange(startdate, "next")}
                />
                <label htmlFor="fromdate">
                  <img
                    src={require("../../assets/images/calender-icon.png")}
                    alt="cal"
                    // onChange={(e) => handleChange(e, "calander")}
                    style={{ margin: "0px 30px", cursor: "pointer" }}
                  />
                </label>
              </div>
            </div>
            <div className="d_w_m_y">
              <div
                className="ddd"
                style={states.isDay ? stylesss.dayAct : null}
                onClick={() => handleClick("d")}
              >
                <p>D</p>
              </div>
              <div
                className="mmm"
                style={states.isMonth ? stylesss.monthAct : null}
                onClick={() => handleClick("m")}
              >
                <p>M</p>
              </div>
              <div
                className="yyy"
                style={states.isYear ? stylesss.yearAct : null}
                onClick={() => handleClick("y")}
              >
                <p>Y</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className={styles.top}>
        <GridContainer>
          <div className={styles.calSec}>
            <div>
              <GridItem>
                <div className={styles.cal}>
                  <div className={styles.left}>
                    <div className={styles.options}>
                      <div className={styles.searchIcon}>
                        <img src={search_icon} alt="search" />
                      </div>
                      <AssessmentTableDropdown
                        name={"mode"}
                        changeMode={changeMode}
                        modeslabel={"mode"}
                        value={selectedValues.modeId}
                        options={states.modeOptions}
                        selectedId={"assessment_mode_id"}
                        displayName={"name"}
                        defaultName={"All Mode"}
                        styles={styles}
                      />
                      <AssessmentTableDropdown
                        name={"organizer"}
                        changeMode={changeMode}
                        modeslabel={"Organizer"}
                        value={selectedValues.organiserId}
                        options={states.organiserOptions}
                        selectedId={"organiser_type_id"}
                        displayName={"name"}
                        defaultName={"All Organizers"}
                        styles={styles}
                      />
                      <AssessmentTableDropdown
                        name={"type"}
                        changeMode={changeMode}
                        modeslabel={"Type"}
                        value={selectedValues.typeId}
                        options={states.typeOptions}
                        selectedId={"assessment_type_id"}
                        displayName={"name"}
                        defaultName={"Assessment Type"}
                        styles={styles}
                      />
                      <AssessmentTableDropdown
                        name={"studyCircle"}
                        changeMode={changeMode}
                        modeslabel={"Study Circle"}
                        value={selectedValues.studyCircleId}
                        options={states.studyOptions}
                        selectedId={"study_circle_id"}
                        displayName={"study_circle_name"}
                        defaultName={"All Study Circle"}
                        styles={styles}
                      />
                      <AssessmentTableDropdown
                        name={"subject"}
                        changeMode={changeMode}
                        modeslabel={"Subjects"}
                        value={selectedValues.subjectId}
                        options={states.subjectOptions}
                        selectedId={"subject_id"}
                        displayName={"subject"}
                        defaultName={"All Subjects"}
                        styles={styles}
                      />
                    </div>
                  </div>
                  <div className={styles.right}>
                    <div className={styles.sessionCal}>{calandarSection()}</div>
                  </div>
                </div>
              </GridItem>
            </div>
          </div>
          <AssessmentTable
            states={states}
            styles={styles}
            tableClick={tableClick}
            isLoading={isLoading}
            sortSession={sortSession}
            setStates={setStates}
          />
        </GridContainer>
      </div>
    </React.Fragment>
  );
};
export default Session;
