import React from "react";
import { Stage, Layer, Rect, Text, Line, Group } from 'react-konva';
import "./HandRaiseBarGraph.css"

const HandRaiseBarGraph = (props) => {
    const { chartData, xLineLength } = props

    let maxHandraise = Math.max(...chartData.map(obj => obj.number_of_handraise))
    let range = (Number(((maxHandraise / 10).toString().split(".")[0])) + 1) * 10
    if (range <= 20) {
        range = 50
    }
    const yLabelInBetweenDist = Math.ceil(range / 10)
    const multiplicationFactor = Math.round((400 / range))
    const yLineHeight = range * multiplicationFactor
    const originXvalueforLines = 60
    const originYValueforLines = 20
    const questionRectHeight = 30
    const questionRectWidth = 30
    const handraiseRectWidth = 30
    const originalXvalueForQuestion = 80
    const originXvalueForHandraise = 150
    const questionRectYPosition = yLineHeight - questionRectHeight

    console.log("yLineHeight", yLineHeight)
    console.log("multiplicationFactor", multiplicationFactor)
    console.log("yLabelInBetweenDist", yLabelInBetweenDist)
    console.log("range", range)
    const handleQuestionClick = () => {
        alert("Hello")
    }

    let yLabelPoints = []
    for (let i = 0; i <= range; i = i + 10) {
        yLabelPoints.push(i)
    }
    console.log("yLabelPoints", yLabelPoints)

    return (
        <Stage width={xLineLength} height={yLineHeight + 100} >
            <Layer>
                <Line
                    x={originXvalueforLines}
                    y={originYValueforLines}
                    points={[0, 0, 0, yLineHeight]}
                    stroke="rgba(233, 234, 238, 1)"
                />
                <Line
                    x={originXvalueforLines}
                    y={yLineHeight + originYValueforLines}
                    points={[0, 0, xLineLength - 100, 0]}
                    stroke="rgba(233, 234, 238, 1)"
                />
                {yLabelPoints.map((ele, index) => {
                    // console.log("yLineHeight - (40) * index", yLineHeight - (40) * index, index, ele)
                    return (
                        <Text
                            text={ele.toString()}
                            x={40}
                            y={(yLineHeight - 40 * index) + originYValueforLines}
                            fill="rgba(62, 60, 60, 1)"
                        />
                    )
                })}
                {chartData?.map((obj, index) => {
                    const height = obj.number_of_handraise * multiplicationFactor
                    return (
                        <Group>
                            <Rect
                                x={index ? (140 * index) + 80 : originalXvalueForQuestion}
                                y={questionRectYPosition + originYValueforLines}
                                width={questionRectWidth}
                                height={questionRectHeight}
                                stroke={"rgba(245, 166, 35, 1)"}
                                shadowBlur={0}
                                fill="#F5A623"
                                onClick={handleQuestionClick}
                            />
                            <Text
                                text={index ? `Q${index + 1}` : `Q1`}
                                x={index ? (140 * index) + 85 : originalXvalueForQuestion + 5}
                                y={questionRectYPosition + 10 + originYValueforLines}
                                fill="rgba(201, 125, 0, 1)"
                            />
                            <Rect
                                x={index ? (140 * index) + 150 : originXvalueForHandraise}
                                y={yLineHeight - height + originYValueforLines}
                                width={handraiseRectWidth}
                                height={height}
                                fill="rgba(31, 100, 255, 1)"
                                shadowBlur={0}
                            />
                            <Text
                                text={obj.number_of_handraise}
                                x={index ? (140 * index) + 160 : originalXvalueForQuestion + 80}
                                y={yLineHeight - height - 20 + originYValueforLines}
                                fill="rgba(12, 48, 129, 1)"
                            />
                        </Group>
                    )
                })}
            </Layer>
        </Stage>
    )
}

export default HandRaiseBarGraph