import { CUBEJS_API } from "../../config/Api";
import cubejs from "@cubejs-client/core";
import { toast } from "react-toastify";

function cubeFunction() {
  var cubeJsToken = window.localStorage.getItem("cmn_cubeJs_token");
  var cubejsAPIFunction = cubejs(cubeJsToken, {
    apiUrl: CUBEJS_API,
  });
  return cubejsAPIFunction;
}

function handleError(error) {
  console.log(error);
  if (error?.response?.error) {
    console.log(error.response.error);
    toast.error(error.response.error);
  }
  return error;
}

//RA-DASH-01 - Summary by Organizer
export async function fetchOrganiserSummary(institution_id) {
  // console.log("institution_id", institution_id)
  const cubeJsApi = cubeFunction();
  const qScore = {
    measures: ["Assessment.count", "AssessmentResponse.questionsCount"],
    timeDimensions: [
      {
        dimension: "Assessment.date",
      },
    ],
    order: {
      "Assessment.count": "desc",
    },
    dimensions: ["AssessmentOrganizer.name"],
    filters: [
      {
        member: "AssessmentResponse.institutionId",
        operator: "equals",
        values: [`${institution_id}`],
      },
      {
        member: "AssessmentOrganizer.name",
        operator: "set",
      },
    ],
  };
  try {
    // const result = await cubeJsApi.load(qScore);
    const startTime = Date.now();
    const result = await cubeJsApi.load(qScore);
    const endTime = Date.now();
    const timeTaken = endTime - startTime;
    console.log("Time taken for fetchOrganiserSummary", timeTaken);
    console.log("result inside cube query --fetchOrganiserSummary", result);
    return result?.loadResponses[0]?.data;
  } catch (error) {
    handleError(error);
  }
}

// RA-DASH-02 Fetch recent session table
export async function fetchRecentSessionTable(institution_id) {
  const cubeJsApi = cubeFunction();
  const qScore = {
    dimensions: [
      "AssessmentResponse.assessmentId",
      "AssessmentResponse.assessmentDate",
      "GroupSession.code",
      "Topic.name",
      "AssessmentType.name",
      "AssessmentOrganizer.name",
      "StudyCircle.name",
      // "StudyCircle.id",
      "AssessmentResponse.studyCircleId",
      "Assessment.groupSessionId",
      "Assessment.startTime",
      "Assessment.endTime",
    ],
    order: {
      "AssessmentResponse.assessmentDate": "desc",
    },
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
      },
    ],
    measures: [
      // "AssessmentResponse.attendanceTotal",
      // "AssessmentResponse.attendancePresent",
      "AssessmentResponse.avgVelocity",
      "AssessmentResponse.totalCorrects",
      "AssessmentResponse.totalNearCorrects",
      "AssessmentResponse.totalIncorrects",
      "AssessmentResponse.totalNotAnswereds",
      "HandRaise.count",
      "AssessmentResponse.studentAttendancePresent",
      "AssessmentResponse.studentAttendanceTotal",
    ],
    filters: [
      {
        member: "AssessmentResponse.institutionId",
        operator: "equals",
        values: [`${institution_id}`],
      },
    ],
    limit: 5,
  };

  try {
    // const result = await cubeJsApi.load(qScore);
    // console.log("result inside cube query --fetchResentSessionTable", result)
    const startTime = Date.now();
    const result = await cubeJsApi.load(qScore);
    const endTime = Date.now();
    const timeTaken = endTime - startTime;
    console.log("Time taken for fetchRecentSessionTable", timeTaken);
    return result?.loadResponses[0]?.data;
  } catch (error) {
    handleError(error);
  }
}

// Trend Query 1(RA-DASH-03-01)
export async function fetchDashboardTrendTable(institution_id) {
  const cubeJsApi = cubeFunction();
  const qScore = {
    order: {
      "AssessmentResponse.assessmentDate": "desc",
    },
    measures: ["AssessmentResponse.avgVelocity"],
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
      },
    ],
    filters: [
      {
        member: "AssessmentResponse.institutionId",
        operator: "equals",
        values: [`${institution_id}`],
      },
      {
        member: "Student.name",
        operator: "set",
      },
    ],
    dimensions: [
      "AssessmentResponse.studentId",
      "StudyCircleStudent.rollNumber",
      "Student.name",
      "StudyCircle.name",
      "Student.profilePicUrl",
      "AssessmentResponse.raStudentGroupKey",
    ],
  };

  try {
    // const result = await cubeJsApi.load(qScore);
    // console.log("result inside cube query --fetchDashboardTrendTable", result)
    const startTime = Date.now();
    const result = await cubeJsApi.load(qScore);
    const endTime = Date.now();
    const timeTaken = endTime - startTime;
    console.log("Time taken for fetchDashboardTrendTable", timeTaken);
    return result?.loadResponses[0]?.data;
  } catch (error) {
    handleError(error);
  }
}

// Trend Query 2(RA-DASH-03-02)
export async function fetchDashboardTrend(institution_id) {
  const cubeJsApi = cubeFunction();
  const qScore = {
    measures: [
      "AssessmentResponse.avgVelocity",
      "AssessmentResponse.rowNumStudentTrend",
    ],
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
        granularity: "month",
      },
    ],
    dimensions: ["AssessmentResponse.raStudentGroupKey"],

    filters: [
      {
        member: "AssessmentResponse.institutionId",
        operator: "equals",
        values: [`${institution_id}`],
      },
      {
        member: "Student.name",
        operator: "set",
      },
    ],
  };

  try {
    // const result = await cubeJsApi.load(qScore);
    // console.log("result inside cube query --fetchDashboardTrend", result)
    const startTime = Date.now();
    const result = await cubeJsApi.load(qScore);
    const endTime = Date.now();
    const timeTaken = endTime - startTime;
    console.log("Time taken for fetchDashboardTrend", timeTaken);
    console.log(
      "result?.loadResponses[0]?.data",
      result?.loadResponses[0]?.data,
    );
    return result?.loadResponses[0]?.data;
  } catch (error) {
    handleError(error);
  }
}
