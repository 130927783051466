import React from "react";
import "./HandRaiseBarDetail.css"

const HandRaiseBarDetail = (props) => {
    const { text, num } = props

    return (
        <div className="raise-div">
            <p className="raise-num">{num}</p>
            <p className="raise-name">{text}</p>
        </div>
    )
}

export default HandRaiseBarDetail