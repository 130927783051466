import React from 'react'
import { Bar } from 'react-chartjs-2'
import styles from './HandRaiseChart.module.css'

const HandRaiseChart = ({ values }) => {
  // console.log('values from handraise', values)
  const data = {
    labels: ["Correct", "Near Correct", "Incorrect", "Not Answered"],
    datasets: [
      {
        label: "series one",
        data: [900, 550, 950, 350],
        borderColor: "",
        backgroundColor: "#7CB5EC",
        pointBackgroundColor: "black",
        pointBorderColor: "red",
      },
      {
        label: "series two",
        data: [950, 850, 350, 700],
        borderColor: "pink",
        backgroundColor: "#313131",
        pointBackgroundColor: "orange",
        pointBorderColor: "cyan",
        defaultFontSize: 8
      },
    ],
  };
  const options = {
    plugins: {
      labels: false,
    },
    responsive: true,
    title: {
      display: false,
      text: 'Bar chart',
      position: 'top',
      Align: 'end'
    },
    legend: false,
    dataset: {
      barPercentage: 0.6,
      categoryPercentage: 0.7,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
            max: 1250,
            stepSize: 250,
            padding: 20,
          },
          gridLines: {
            drawBorder: false
          }
        }
      ],
      xAxes: [{
        gridLines: {
          display: false
        },
        // categoryPercentage: .7,
        // barPercentage:.6,
        ticks: {
          maxTicksLimit: 20,
          padding: 5
        },
      }]
    }
  };
  return (
    <div>
      <div className={styles.main}>
        <div className={styles.left}>
          <div className={styles.top}>4hrs 24min.</div>
          <div className={styles.bottom}>Total duration</div>
        </div>
        <div className={styles.right}>
          <div className={styles.top}>{3478}</div>
          <div className={styles.bottom}>Hand raise</div>
        </div>
      </div>
      <Bar data={data} options={options} width='300px' height='250px' />
    </div>
  );
}

export default HandRaiseChart
