function roundTwoDecimal(value) {
    const result = Math.round(value * 100) / 100;
    return result;
}

export async function performanceSpeed(speed) {
    if (speed !== 0) {
        var dataObj = {};
        if (speed === null) {
            dataObj = {
                time: 0,
                timeMark: [
                    { value: 0, label: "0" },
                    { value: 60, label: 60 },
                ],
            };
        } else {
            speed = roundTwoDecimal(speed);
            dataObj = {
                time: roundTwoDecimal(speed),
                timeMark: [
                    { value: 0, label: "0" },
                    { value: speed, label: speed },
                    { value: 60, label: 60 },
                ],
            };
        }
        // console.log("dataObj inside speedPref", dataObj);
        return dataObj;
    } else {
        return {
            time: 50,
            timeMark: [
                { value: 0, label: "0" },
                { value: 3, label: 3 },
                { value: 60, label: 60 },
            ],
        };
    }
}
