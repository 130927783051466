export { default as trend } from "../images/graph-dashboard.png";
export { default as gause } from "../images/read-velocity.png";
// export {default as greenArrow} from '../images/images/green_arrow.png'
export { default as greenArrow } from '../images/green_arrow.png'
export { default as handRaise } from '../images/raise-hand.png'
export { default as redArrow } from '../images/red-arrow.png'
export { default as orangeArrow } from '../images/orange-arrow.png'
export { default as Sequreblue } from '../images/sequre-blue.png'
export { default as Sequregrey } from '../images/sequre-gery.png'
export { default as Sequregreen } from '../images/sequre-green.png'
export { default as Sequrered } from '../images/sequre-red.png'